import { Decimal } from 'decimal.js'
import { existProperty } from '~/utils/object.utils'

export const calculateTotalTooltip = (tooltipDataPoints) => {
  return tooltipDataPoints
    .reduce((acc, { raw }) => {
      const value = existProperty(raw, 'y') ? raw.y : raw
      return acc.plus(value)
    }, new Decimal(0))
    .toNumber()
}
